import formatDateTime from "./DateTime";

export const feedUrl = 'https://gagoworks.azurewebsites.net/api/fetch-medium-feed?code=_-rdbcDHrSvRbLjYDyYyimDYYmw-Ssms2uCSaB-nDwYRAzFuvGc_gw%3D%3D';

export const fetchMediumFeed = async (numPosts) => {
  try {
    const res = await fetch(feedUrl);
    const xmlText = await res.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, 'text/xml');
    const itemElements = xmlDoc.querySelectorAll('item');
    console.log(itemElements);
    const feedItems = Array.from(itemElements)
      .slice(0, numPosts > 0 ? numPosts : undefined)
      .map(itemElement => ({
        title: itemElement.querySelector('title').textContent,
        link: '/devblog/' + getPostNameFromLink(itemElement.querySelector('link').textContent),
        mediumLink: itemElement.querySelector('link').textContent,
        content: itemElement.querySelector('content,encoded').textContent,
        description: itemElement.querySelector('content,encoded').textContent,
        thumbnail: fetchFirstImageFromContent(itemElement.querySelector('content,encoded').textContent) || '/images/thumbnail-fallback.webp',
        excerpt: truncateString(stripHTMLTags(itemElement.querySelector('content,encoded').textContent), 140),
        publishedAt: formatDateTime(itemElement.querySelector('pubDate').textContent)
      }));
    return feedItems;
  } catch (error) {
    throw new Error('Failed to fetch Medium feed');
  }
};

const fetchFirstImageFromContent = (html) => {
  return html.toString().match(/<img[^>]+src="([^">]+)"/)[1];
};

const stripHTMLTags = (html) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  return tempElement.textContent || tempElement.innerText || '';
};

const truncateString = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + '...';
  }
};

export const getPostNameFromLink = (postLink) => {
  const lastSegment = postLink.split('/').pop(); // Extract the last segment from the link
  const postIdWithoutQueryString = lastSegment.split('?')[0]; // Remove any query string
  const lastHyphenIndex = postIdWithoutQueryString.lastIndexOf('-'); // Find the last hyphen index
  const postName = postIdWithoutQueryString.substring(0, lastHyphenIndex); // Remove everything after the last hyphen
  return postName;
};

export default fetchMediumFeed;