import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchMediumFeed, getPostNameFromLink } from '../utils/Medium';
import BackButton from './BackButton';
import RecentPosts from './RecentPosts';
import './BlogPost.scss';

const BlogPost = () => {
  const { postName } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect((navigate) => {
    async function fetchPost() {
      try {
        const feedItems = await fetchMediumFeed();
        const post = feedItems.find(item => postName === getPostNameFromLink(item.mediumLink));
        if (post) {
            setPost(post);
        } else {
            setError('Post not found');
            navigate('/not-found');
        }
      } catch (error) {
        setError('Failed to fetch post');
      }
    }

    fetchPost();
  }, [postName, navigate]);

  if (error) {
    return (
      <div>
        <BackButton to="/" ariaLabel="Go back to home page" />
        <div id="devblog-post" data-scroll-section="devblog-post">
          <div className="devblog-container devblog-container--error">
            <h3>{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div>
        <BackButton to="/" ariaLabel="Go back to home page" />
        <div id="devblog-post" data-scroll-section="devblog-post">
          <div className="devblog-container devblog-container--loading">
            <h3>Loading...</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <BackButton to="/" ariaLabel="Go back to home page" />
      <div id="devblog-post" data-scroll-section="devblog-post">
        <div className="devblog-container">
          <h1>{post.title}</h1>
          <hr></hr>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
          <hr></hr>
          <RecentPosts/>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
