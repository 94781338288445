import React from 'react';
import classNames from 'classnames';
import './Button.scss';

const Button = ({ color, variant, onClick, children }) => {
  const buttonClass = classNames('button', {
    'btn': true,
    'primary': color === 'primary',
    'secondary': color === 'secondary',
    'light': color === 'light',
    'dark': color === 'dark',
    'normal': variant === 'normal',
    'invert': variant === 'invert',
  });

  return (
    <button className={buttonClass} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
