import React, { useState, useEffect } from 'react';
import './RecentPosts.scss';
import Button from './Button';
import fetchMediumFeed from '../utils/Medium';

const RecentPosts = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      async function fetchData() {
          try {
            setLoading(true);
            const feedItems = await fetchMediumFeed(4);
            setItems(feedItems);
            setLoading(false);
          } catch {
            setLoading(false);
            setError(true);
          }
      }

      fetchData();
  }, []);

  if (error) {
    return (
      <section className="recent-posts-container">
        <h2 data-scroll data-scroll-speed="1">
          Recent Posts
        </h2>
        <ul data-scroll data-scroll-speed="2">
          <p>Failed to fetch data, please try again later.</p>
        </ul>
        <a
          href={"https://gagovictor.medium.com/"}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          <Button
            color="light"
            variant="invert"
          >
            Read on Medium
          </Button>
        </a>
      </section>
    );
  }

  if (loading) {
    return (
      <section className="recent-posts-container">
        <h2 data-scroll data-scroll-speed="1">
          Recent Posts
        </h2>
        <div data-scroll data-scroll-speed="2">
          <p>Loading...</p>
        </div>
        <a
          href={"https://gagovictor.medium.com/"}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          <Button
            color="light"
            variant="invert"
          >
            Read on Medium
          </Button>
        </a>
      </section>
    );
  }

  return (
    <div className="recent-posts-container">
      <h2 data-scroll data-scroll-speed="1">
        Recent Posts
      </h2>
      <div className="posts-grid" data-scroll-speed="2">
        {items.map((item, index) => (
          <div className="post-card" key={index}>
            <a href={item.link}>
              <div className="post-card__thumbnail">
                <img src={item.thumbnail} alt={item.title} />
              </div>
              <h3 className="post-card__title">
                {item.title}
              </h3>
              <sub className="post-card__date">
                {item.publishedAt}
              </sub>
              <p className="post-card__excerpt">
                {item.excerpt}
              </p>
            </a>
          </div>
        ))}
      </div>
      <a
        href={"https://gagovictor.medium.com/"}
        target={"_blank"}
        rel={"noopener noreferrer"}
        data-scroll-speed="3"
      >
        <Button
          color="light"
          variant="invert"
        >
          Read more on Medium
        </Button>
      </a>
    </div>
  );
};

export default RecentPosts;
