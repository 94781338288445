import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BackButton.scss';

const BackButton = ({ to, ariaLabel }) => {
  
  const location = useLocation();

  return (
    <div>
      {location.pathname !== "/" && (
        <Link to={to} className="back-button" aria-label={ariaLabel}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>
        </Link>
      )}
    </div>
  );
};

export default BackButton;
