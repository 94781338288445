export const formatDateTime = (inputDate) => {
    // Parse the input date string
    const date = new Date(inputDate);
    
    // Months array to get the month name
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    // Get the day, month, year, hour, and minute from the parsed date
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    
    // Format the date components
    const formattedDate = `${months[month]} ${day}, ${year} at ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    
    return formattedDate;
}

export default formatDateTime;