import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import seedrandom from 'seedrandom';


const ThreeScene = () => {
  const sceneRef = useRef();
  const mouseX = useRef(0);
  const mouseY = useRef(0);
  const camera = useRef();
  const particles = useRef();
  const nodes = useRef([]);
  const lines = useRef([]);
  const lookIntensity = 1;
  const nodesCount = 30;
  const waveSegments = 50;
  const initialFov = 75;
  const targetFov = 125;

  useEffect(() => {
    const textureLoader = new THREE.TextureLoader();
    const seed = 'gago.work';
    let scrollPosition = 0;
    
    seedrandom(seed, { global: true });

    const scene = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(initialFov, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: false });
    renderer.setSize(window.innerWidth, window.innerHeight);
    sceneRef.current.appendChild(renderer.domElement);

    // Set a dark background color
    scene.background = new THREE.Color(0x111122);

    // Create nodes representing neurons
    const nodeGeometry = new THREE.SphereGeometry(0.025, 32, 32);
    const nodeMaterial = new THREE.MeshStandardMaterial({
      color: 0x45289a,
      emissive: 0x000000,
      metalness: 0,
      roughness: 0.1,
      side: THREE.DoubleSide,
    });
    
    for (let i = 0; i < nodesCount; i++) {
      const node = new THREE.Mesh(nodeGeometry, nodeMaterial);
      node.position.x = Math.random() * 50 - 25;
      node.position.y = Math.random() * 50 - 25;
      node.position.z = Math.random() * 50 - 25;
      nodes.current.push(node);
      scene.add(node);
    }

    // Create oscillating lines connecting nodes with lightning effect
    const pulseMaterial = new THREE.LineBasicMaterial({
      transparent: true,
      linewidth: 2,
      blending: THREE.AdditiveBlending,
      vertexColors: true,
      depthWrite: false,
    });    

    for (let i = 0; i < nodes.current.length; i += 2) {
      for (let j = i + 1; j < nodes.current.length; j += 2) {
        const points = [];
        const colors = [];

        for (let k = 0; k <= waveSegments; k++) {
          const t = k / waveSegments;
          const amplitude = 0.5;
          const frequency = 2;
          const x = THREE.MathUtils.lerp(nodes.current[i].position.x, nodes.current[j].position.x, t);
          const y = THREE.MathUtils.lerp(nodes.current[i].position.y, nodes.current[j].position.y, t);
          const z = THREE.MathUtils.lerp(nodes.current[i].position.z, nodes.current[j].position.z, t);
          const offsetY = amplitude * Math.sin(frequency * t * Math.PI * 2);
          points.push(new THREE.Vector3(x, y + offsetY, z));

          // Add color variation along the line to simulate oscillation between color and transparency
          const oscillation = 0.5 + 0.5 * Math.sin((frequency * t + Date.now() * 0.001) * Math.PI * 2); // Oscillate between 0 and 1 over time
          const color = new THREE.Color(0x88aaff);
          color.multiplyScalar(oscillation); // Scale color based on oscillation
          colors.push(color.r, color.g, color.b, 0);
        }

        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 4));

        const line = new THREE.Line(geometry, pulseMaterial);
        lines.current.push(line);
        scene.add(line);
      }
    }

    // Particle field
    const particleGeometry = new THREE.BufferGeometry();
    const particleTexture = textureLoader.load('/images/particle.png');
    const particleMaterial = new THREE.PointsMaterial({
      map: particleTexture,
      transparent: true,
      size: 0.005
    });
    const particlesData = [];
    const radius = 15;

    for (let i = 0; i < 15000; i++) {
      // Generate random spherical coordinates
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos(2 * Math.random() - 1);

      // Convert spherical coordinates to Cartesian coordinates
      const x = radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.sin(phi) * Math.sin(theta);
      const z = radius * Math.cos(phi);
      particlesData.push(x, y, z);
    }

    particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(particlesData, 3));
    particles.current = new THREE.Points(particleGeometry, particleMaterial);
    scene.add(particles.current);

    // Set up camera position
    camera.current.position.copy({ x: 0, y: 0, z: 0 });

    // Add fog to the scene
    scene.fog = new THREE.Fog(0x111122, 0, 50);
    

    // Animation function
    const animate = () => {
      requestAnimationFrame(animate);
      
      // Update camera position based on mouse movement
      const mouseXCentered = mouseX.current - 0.5; // Normalize mouseX to be centered
      const mouseYCentered = mouseY.current - 0.5; // Normalize mouseY to be centered

      camera.current.position.x += mouseXCentered * lookIntensity;
      camera.current.position.y += -mouseYCentered * lookIntensity;
      // camera.current.position.z += distanceFromCenter * maxDistance;

      // Lerp camera position back to the initial position
      camera.current.position.lerp({ x: 0, y: 0, z: 5 }, 0.1);

      // Rotate the entire scene based on the scroll direction
      const deltaScroll = scrollPosition - window.scrollY;
      scene.rotation.x += (deltaScroll * 0.001);
      // scene.rotation.y += (deltaScroll * 0.002);
      // scene.rotation.z += (deltaScroll * 0.003);

      // Update the current scroll position
      scrollPosition = window.scrollY;

      // Perform actions based on the scroll position
      const fov = THREE.MathUtils.lerp(initialFov, targetFov, Math.min(window.scrollY / window.innerHeight, 1));
      camera.current.fov = fov;
      camera.current.updateProjectionMatrix();

      camera.current.lookAt(scene.position);
  
      // Move particles between nodes
      particles.current.position.x += Math.sin(Date.now() * 0.0001) * 0.001;
      particles.current.position.y += Math.cos(Date.now() * 0.0001) * 0.001;
        
      // Update color of nodes
      for (let i = 0; i < nodes.current.length; i++) {
        const node = nodes.current[i];
        const color = node.material.emissive;

        // Add color variation to simulate oscillation between color and transparency
        const oscillation = 0 + 0.5 * Math.sin((Date.now() * 0.001) * Math.PI * 2);
        color.r = oscillation;
        color.g = 0.5 + 0.5 * Math.sin((Date.now() * 0.001) * Math.PI * 1);
        color.b = 0.1 + 0.1 * Math.random() * Math.sin((Date.now() * 0.001) * Math.PI * 0.3);
      }

      // Update color positions along each line to create the effect of gradient movement
      for (let i = 0; i < lines.current.length; i++) {
        if(Math.random() > 0.5)
          continue;
        const line = lines.current[i];
        const colors = line.geometry.attributes.color.array;

        for (let j = 0; j < colors.length; j += 4) {
          if(Math.random() > 0.5)
            colors[j + 1] = 0.5 + 0.5 * Math.sin((colors[j + 3] + Date.now() * 0.001) * Math.PI * 1);
          colors[j + 3] = 0.1 + 0.1 * Math.random() * Math.sin((colors[j + 3] + Date.now() * 0.001) * Math.PI * .3);
        }

        line.geometry.attributes.color.needsUpdate = true;
      }

      renderer.render(scene, camera.current);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      camera.current.aspect = newWidth / newHeight;
      camera.current.updateProjectionMatrix();

      renderer.setSize(newWidth, newHeight);
    };

    // Handle mouse move
    const handleMouseMove = (event) => {
      mouseX.current = (event.clientX / window.innerWidth) * 2 - 1;
      mouseY.current = (event.clientY / window.innerHeight) * 2 - 1;
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <div ref={sceneRef} />;
};

export default ThreeScene;
