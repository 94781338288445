import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useRef } from 'react';
import BlogPost from './components/BlogPost';
import Resume from './components/Resume';
import NotFound from './components/NotFound';
import Footer from './components/Footer';
import ThreeScene from './components/ThreeScene';
import BackButton from './components/BackButton';
import './styles/theme.scss';
import './App.scss';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import './styles/theme.scss';
import './App.scss';

const App = () => {
  const containerRef = useRef(null);

  return (
    <div id="app-container">
      {/* <LoadingScreen /> */}
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          snap: true,
        }}
        containerRef={containerRef}
        watch={['scroll', 'direction']}
        onLocationChange={(scroll) => {
          scroll.scrollTo(0, { duration: 0, disableLerp: true });
        }}
        onUpdate={(scroll) => {
        }}
      >
        <main data-scroll-container ref={containerRef} id="app-content">
          <Router>
            <nav>
              <BackButton to="/" ariaLabel="Go back to home page"/>
            </nav>
            <Routes>
              <Route path="/" element={<Resume />} />
              {/* <Route path="/devblog" element={<RecentPosts />} /> */}
              <Route path="/devblog/:postName" element={<BlogPost />} />
              <Route component={NotFound} />
            </Routes>
          </Router>
        </main>
      </LocomotiveScrollProvider>
      <div id="scene-container">
        <ThreeScene />
      </div>
      <Footer />
    </div>
  );
}

export default App;
